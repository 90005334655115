<template>
  <VxForm v-slot="context" :disabled="disabled" @submit="submit">
    <v-card class="auth-card">
      <v-card-title v-if="title" class="text-h5">
        {{ title }}
      </v-card-title>
      <slot v-bind="{ ...context, submitting }" />
      <v-card-text v-for="error in errors" :key="error" class="py-0">
        <VxAlert type="error">{{ error }}</VxAlert>
      </v-card-text>
      <v-card-actions class="flex-column">
        <VxBtn
          type="submit"
          data-testid="submitBtn"
          :loading="submitting"
          block
        >
          {{ submitText }}
        </VxBtn>
        <div class="auth-card__links">
          <slot name="links" />
        </div>
      </v-card-actions>
    </v-card>
  </VxForm>
</template>

<script>
import { VxAlert, VxForm, VxBtn } from "@/core-ui";

export default {
  name: "AuthCard",
  components: { VxAlert, VxForm, VxBtn },
  props: {
    title: {
      type: String,
      default: "",
    },
    submitText: {
      type: String,
      default: "Submit",
    },
  },
  data: () => ({
    errors: [],
    submitting: false,
  }),
  computed: {
    disabled() {
      return this.$raiPos && !!this.$raiPos;
    },
  },
  methods: {
    submit(context) {
      this.submitting = true;
      this.$emit("submit", {
        ...context,
        resolve: () => {
          context.resolve();
          this.submitting = false;
        },
        setErrors: (errors) => {
          this.errors = errors;
          this.submitting = false;
        },
      });
    },
  },
};
</script>

<style lang="scss">
.auth-card {
  .v-card__title + .v-card__text {
    padding-top: 16px;
  }

  .v-card__actions {
    padding: 16px;
    gap: 1rem;
  }
}

.auth-card__links {
  display: flex;
  width: 100%;
}
</style>
