<template>
  <PosLogin v-if="blockOnPos" />
  <AuthCard
    v-else
    :title="$t('auth.loginView.title')"
    :submit-text="$t('auth.loginView.submitBtnText')"
    @submit="onSubmit"
  >
    <v-card-text class="pb-0">
      <VxTextField
        v-model="form.email"
        :label="$t('auth.loginView.emailField.label')"
        type="email"
        name="email"
        :placeholder="$t('auth.loginView.emailField.placeholder')"
        rules="required|email"
        autocomplete="username"
        append-icon="$email"
        data-testid="emailField"
        autofocus
      />
      <VxTextField
        v-model="form.password"
        :label="$t('auth.loginView.passwordField.label')"
        name="password"
        rules="required"
        :type="passwordInputType"
        :placeholder="$t('auth.loginView.passwordField.placeholder')"
        autocomplete="current-password"
        data-testid="passwordField"
        :append-icon="passwordVisiblityIcon"
        @click:append="onPasswordVisibilityToggle"
      />
      <VxAlert v-if="alert" :type="alert.type">
        {{ alert.message }}
      </VxAlert>
    </v-card-text>
    <template #links>
      <v-spacer />
      <VxBtn class="px-0" text small @click="onForgotPassword">
        {{ $t("auth.loginView.forgotPasswordText") }}
      </VxBtn>
    </template>
  </AuthCard>
</template>

<script>
import AuthCard from "./components/AuthCard.vue";
import PosLogin from "./components/PosLogin.vue";
import { VxAlert, VxTextField, VxBtn } from "@/core-ui";

export default {
  name: "LoginView",
  components: { AuthCard, VxAlert, VxTextField, VxBtn, PosLogin },
  data: () => ({
    visiblePassword: false,
    form: { email: null, password: null },
    blockOnPos: false,
  }),
  computed: {
    passwordInputType() {
      return this.visiblePassword ? "text" : "password";
    },
    passwordVisiblityIcon() {
      return this.visiblePassword ? "$visibilityOff" : "$visibilityOn";
    },
    alert() {
      return this.$route.params.alert;
    },
  },
  mounted() {
    this.blockOnPos = !!this.$raiPos;
  },
  methods: {
    async refresh() {
      window.location.reload();
    },
    async onSubmit({ values, setErrors }) {
      try {
        await this.$store.dispatch("auth/authenticate", {
          credentials: values,
        });

        if (this.$raiPos) {
          this.$raiPos.autologin();
          const { token, email } = this.$store.state.auth;
          this.$raiPos.setToken(token, email);
        }

        this.$router.push(this.$route.query.go || "/");
      } catch {
        setErrors([this.$t("auth.loginView.invalidEmailOrPasswordText")]);
      }
    },
    onPasswordVisibilityToggle() {
      this.visiblePassword = !this.visiblePassword;
    },
    onForgotPassword() {
      this.$router.push({ name: "auth.forgot-password" });
    },
  },
};
</script>
