<template>
  <VxConfirmDialog hide-cancel :value="true" ok-text="Retry" @ok="refresh">
    ResaleAI POS failed to login automatically.
  </VxConfirmDialog>
</template>

<script>
import { VxConfirmDialog } from "@/core-ui";

export default {
  name: "PosLogin",
  components: { VxConfirmDialog },
  methods: {
    refresh() {
      window.location.reload();
    },
  },
};
</script>
